import React, { useState } from "react";
import { Button, Form, Nav, Navbar } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { BsPlus, BsCaretUp } from "react-icons/bs";
import { FiMinus, FiPlus } from "react-icons/fi";
import { TiWeatherNight, TiWeatherSunny } from "react-icons/ti";
import { IoReturnDownForwardSharp } from "react-icons/io5";

import { urlDevSystem } from "./dummyData";
import { BiDoorOpen } from "react-icons/bi";
import moment from "moment";

function menuPanel(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = location.pathname;
  const bayz = localStorage.getItem("browserTypeXUID");
  const subscription = localStorage.getItem("subscription").replaceAll('"', "");
  const remaining = localStorage.getItem("remaining");
  const paket = localStorage.getItem("package");
  const paket_id = localStorage.getItem("package_id");
  const urlSystem = localStorage.getItem("urlSystem");
  const [isSub, setIsSub] = useState(0);

  const iconColor = props.theme === "light" ? "black" : "white";
  const icon =
    props.theme === "light" ? (
      <TiWeatherNight size={22} />
    ) : (
      <TiWeatherSunny size={22} />
    );
  function handleClick(id) {
    if (isSub != id) {
      setIsSub(id);
    } else {
      setIsSub(0);
    }
  }
  return (
    <>
      <ul className="main_side">
        <li
          className={
            isActive === "/hakkimizda" || isActive === "/sss" ? "active" : ""
          }
        >
          <Nav.Link onClick={() => handleClick(2)} href="#" id="2">
            Hakkımızda
            {isSub == 2 ? <FiMinus /> : <FiPlus />}
          </Nav.Link>
          <ul className={isSub == 2 ? "show" : ""}>
            <li>
              <Nav.Link href="/hakkimizda">- Hakkımızda</Nav.Link>
            </li>
            <li>
              <Nav.Link href="/sss">- S.S.S.</Nav.Link>
            </li>
            <li>
              <Nav.Link href="/kvkk">- KVKK</Nav.Link>
            </li>
            <li>
              <Nav.Link href="/kullanici-sozlesmesi">
                - Kullanıcı Sözleşmesi
              </Nav.Link>
            </li>
            <li>
              <Nav.Link href="/gizlilik-politikasi">
                - Gizlilik Politikası
              </Nav.Link>
            </li>
            <li>
              <Nav.Link href="/cerez-politikasi">- Çerez Politikası</Nav.Link>
            </li>
            <li>
              <Nav.Link href="/sorumluluk-reddi">- Sorumluluk Reddi</Nav.Link>
            </li>
          </ul>
        </li>
        <li className={isActive === "/tanitim" ? "active" : ""}>
          <Nav.Link href="/tanitim">Tanıtım ve Kullanım</Nav.Link>
        </li>

        <li className={isActive === "/ana-kumanda" ? "active" : ""}>
          <Nav.Link href="/ana-kumanda" className="menuRed">
            Ana Kumanda <span className="menuNew">Yeni</span>
          </Nav.Link>
        </li>

        {/* <li className={isActive === "/bayz" ? "active" : ""}>
          <Nav.Link href="/bayz" className={bayz == 0 ? "disabled" : ""}>
            bay<span style={{ color: "red" }}>Z</span>
          </Nav.Link>
        </li>
        <li className={isActive === "/formasyonlar" ? "active" : ""}>
          <Nav.Link
            href="/formasyonlar"
            className={subscription !== "active" ? "disabled" : ""}
          >
            Formasyonlar
          </Nav.Link>
        </li>
        <li className={isActive === "/fibometre" ? "active" : ""}>
          <Nav.Link
            href="/fibometre"
            className={subscription !== "active" ? "disabled" : ""}
          >
            Fibometre
          </Nav.Link>
        </li> */}

        <li className={isActive === "/gelismis-grafik" ? "active" : ""}>
          <Nav.Link
            href="/gelismis-grafik"
            className={subscription !== "active" ? "disabled" : ""}
          >
            Grafik Paneli
          </Nav.Link>
        </li>

        <li className={isActive === "/strateji" ? "active" : ""}>
          <Nav.Link
            href="/strateji"
            className={subscription !== "active" ? "disabled" : ""}
          >
            Strateji
          </Nav.Link>
        </li>

        <li className={isActive === "/indikatorler" ? "active" : ""}>
          <Nav.Link
            href="/indikatorler"
            className={subscription !== "active" ? "disabled" : ""}
          >
            İndikatörler
          </Nav.Link>
        </li>
        {urlSystem == urlDevSystem ? (
          <li
            className={
              isActive === "/orphan-heikin-endeksi" ||
              isActive === "/heikin-ala-donenler"
                ? "active"
                : "d-none1"
            }
          >
            <Nav.Link onClick={() => handleClick(3)} href="#" id="3">
              Özel Ölçümlemeler
              {isSub == 3 ? <FiMinus /> : <FiPlus />}
            </Nav.Link>
            <ul className={isSub == 3 ? "show" : ""}>
              <li>
                <Nav.Link href="/orphan-ozel-dip-endeksi">
                  - Orphan Dip Endeksi
                </Nav.Link>
              </li>
              <li>
                <Nav.Link href="/orphan-ozel-kesisim-endeksi">
                  - Orphan Kesişim Endeksi
                </Nav.Link>
              </li>
              {/* <li>
                <Nav.Link href="/heikin-ala-donenler">
                  Heikin AL'a Dönenler
                </Nav.Link>
              </li> */}
            </ul>
          </li>
        ) : (
          ""
        )}
        {/* <li className={isActive === "/harmonikler" ? "active" : ""}>
          <Nav.Link href="/harmonikler" className="disabled">
            Harmonikler
          </Nav.Link>
        </li>
        <li>
          <Nav.Link href="#" className="disabled">
            Temel Analiz
          </Nav.Link>
        </li>
        <li>
          <Nav.Link href="#" className="disabled">
            Forum
          </Nav.Link>
        </li> */}
        <li className={isActive === "/favorilerim" ? "active" : ""}>
          <Nav.Link href="/favorilerim">Favorilerim</Nav.Link>
        </li>
        <li className={isActive === "/profilim" ? "active" : ""}>
          <Nav.Link href="/profilim">Profilim</Nav.Link>
        </li>
        <li className={isActive === "/paket-satin-al" ? "active" : ""}>
          <Nav.Link href="/paket-satin-al">Paket Satın Al</Nav.Link>
        </li>
        <li className={isActive === "/destek" ? "active" : ""}>
          <Nav.Link href="/destek">Destek</Nav.Link>
        </li>
        <li className={isActive === "/uye-cikis" ? "active" : ""}>
          <Nav.Link href="/uye-cikis">
            <BiDoorOpen /> Çıkış
          </Nav.Link>
        </li>
      </ul>
      <div
        className="darkMode noMobile"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <div>
          Dark <TiWeatherNight size={22} />
        </div>
        <div>
          <Form.Check
            className="d-flex col"
            type="switch"
            checked={props.theme === "light" ? true : false}
            onChange={props.changeTheme}
            id="custom-switch"
          />
        </div>
        <div>
          <TiWeatherSunny size={22} /> Light
        </div>
      </div>
      <div style={{ marginBottom: "1rem" }}>
        {subscription &&
          (subscription === "passive" ? null : (
            <>
              <Button
                size="sm"
                variant="secondary"
                className="btn-block paketButton mt8"
              >
                {paket.replaceAll('"', "")}
              </Button>
              <Button
                size="sm"
                variant="outline-secondary"
                className="btn-block mt8"
              >
                {remaining} gün
              </Button>
            </>
          ))}
      </div>
    </>
  );
}

export default menuPanel;
